<script setup lang="ts">
  import {useGQlData} from "~/composables/GraphQlHelper";
  import {homeCategoriesQuery} from "~/composables/GraphQlQueries";

  const data = await useGQlData(homeCategoriesQuery, {}, "categories-gql-query");
  const props = defineProps<{
    title: string;
    subtitle?: string;
    isDark?: boolean;
  }>();
</script>
<template>
  <div class="container-xl categories mb-5">
    <div class="title mb-5">
      <h2>{{ props.title }}</h2>
      <span class="text-uppercase" v-if="props.subtitle"></span>
    </div>
    <div class="row g-3">
      <template v-for="category in data?.page?.categories?.categories">
        <div class="col-12 col-md-6 col-xl-4 mb-5 pe-md-5 our-homes with-hover animate-onview animated"
             data-animation="slideInUp">
          <a :href="category?.url" :title="`${category?.title} - View`">
            <div class="category-img d-flex align-items-stretch">
              <NuxtImg :placeholder="250"
                       decoding="async"
                       :src="category?.img?.node?.sourceUrl"
                       :srcset="category?.img?.node?.srcSet"
                       :alt="`${category?.title} Image`"
                       class="w-100 object-fit-cover"
                       height="250" width="250"
                       loading="lazy"
                       format="webp"
                       quality="80"/>
            </div>
          </a>
          <div class="label d-flex align-items-center justify-content-between mt-3">
            <a :href="category?.url" :title="`${category?.title} - View Link`"
               :class="`w-100 d-flex flex-row align-items-center justify-content-between text-nowrap ${props.isDark ? 'text-white' : ''}`">
              <h3 class="category-label">{{ category?.title }}</h3>
              <div class="hover-head-shake animated infinite">
                <span class="me-2 text-uppercase">View Homes</span>
                <NuxtImg quality="80"
                         height="30" width="30"
                         :alt="`View Homes - ${category?.title}`"
                         class="link-img-arrow"
                         :src="props.isDark ? '/arrow-white.svg' : '/arrow.svg'"
                         loading="lazy"/>
              </div>
            </a>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<style scoped lang="css">
  .categories .our-homes {
    opacity: 0;
  }

  .categories .our-homes.animated.slideInUp {
    opacity: 1;
  }
</style>